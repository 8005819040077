import React from 'react';
import './app.css'
import RouteHandler from "./routeHandler";
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {


  return (
    <>
        <div className="main">
          <RouteHandler/>
        </div>
    </>
  )
}

export default App;
