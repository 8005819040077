import "./home.css"
import "../../app.css"
import logo from '../../assets/blackLogoNoPadding.png'
function Home() {

  return <>
    <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <img src={logo} width={150} height={'auto'}/>
    </div>
  </>
}


export default Home;